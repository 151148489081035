import React, { useState, useEffect, useCallback } from "react";
import "../../styling/App.css";
import { forwardRef } from "react";
import Avatar from "react-avatar";

import { CsvBuilder } from "filefy";
import { useWindowResize } from "../FrontEnd_Table/useWindowsResize";

import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import PoliceReport from "@material-ui/icons/Gavel";

import { TextField } from "@material-ui/core";

import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { trackPromise } from "react-promise-tracker";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
//api that the data is manipulated from
const api = axios.create({
    baseURL: `https://6ykkpeemz6.execute-api.us-east-1.amazonaws.com/prod`,
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50",
        },
        secondary: {
            main: "#1499EF",
        },
    },
});

function SupplyChainRecords() {
       var COLUMNS = [
        
        { 
            title: "CaseNumber", 
            field: "CaseNumber",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.CaseNumber}</div>
            ),
            
        },
        {
            title: "Briefing",
            field: "Briefing",
            minWidnt: 200,
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.Briefing}>
                    {rowData.Briefing ? <div style={{width: 200}} >{rowData.Briefing.slice(0, 20)}...</div> : ''}
                </span>
            ),
        },
        {
            title: "Online Communications", 
            field: "OnlineCommunications",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.OnlineCommunications}>
                    {rowData.OnlineCommunications ? rowData.OnlineCommunications.split(' / ').map((item, i) => <div key={i}><a target="_blank" href={item}>{item}</a></div>) : ''}
                </span>
            ),
        },
        { title: "Profile Strength", field: "Profile Strength"},
        { 
            title: "Suspect", 
            field: "Suspect",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.Suspect}</div>
            ),
            
        },
        { 
            title: "Suspect DOB", 
            field: "SuspectDOB",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.SuspectDOB}</div>
            ),
            
        },
        { 
            title: "Employment", 
            field: "Employment",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.Employment}</div>
            ),
            
        },
        {
            title: "Suspect Online Aliases", 
            field: "SuspectOnlineAliases",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.SuspectOnlineAliases}>
                    {rowData.SuspectOnlineAliases ? rowData.SuspectOnlineAliases.split(' / ').map((item, i) => <div style={{width: 200}} key={i}>{item}</div>) : ''}
                </span>
            ),
        },
        { title: "Suspect Profile", field: "SuspectProfile"},
        { title: "Country", field: "Country"},
        { title: "State", field: "State"},
        { 
            title: "Regional Coordinates", 
            field: "RegionalCoordinates",
            render: (rowData) => (
                   <div style={{width: 200}}>
                        {rowData.RegionalCoordinates ? <a href={"http://maps.google.com/maps?z=12&t=h&q=loc:" + rowData.RegionalCoordinates.replace(" / ","+") + "" } target="_blank">{rowData.RegionalCoordinates}</a> : ''}
                   </div>
            ),
        },
        { 
            title: "Suspect Current Location", 
            field: "SuspectCurrentLocation",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.SuspectCurrentLocation}</div>
            ),
            
        },
        {
            title: "Distribution Gateway", 
            field: "DistributionGateway",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.DistributionGateway}>
                    {rowData.DistributionGateway ? rowData.DistributionGateway.split(' / ').map((item, i) => <div key={i}><a target="_blank" href={item}>{item}</a></div>) : ''}
                </span>
            ),
        },
        {
            title: "Trade Corridor", 
            field: "TradeCorridor",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.TradeCorridor}>
                    {rowData.TradeCorridor ? rowData.TradeCorridor.split(' / ').map((item, i) => <div key={i}><a target="_blank" href={item}>{item}</a></div>) : ''}
                </span>
            ),
        },
        { 
            title: "Supply Chain Position", 
            field: "SupplyChainPosition",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.SupplyChainPosition}</div>
            ),
            
        },
        { 
            title: "Suspect Email Addresses", 
            field: "SuspectEmailAddresses",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.SuspectEmailAddresses}>
                    {rowData.SuspectEmailAddresses ? rowData.SuspectEmailAddresses.split(' / ').map((item, i) => <div style={{width: 200}} key={i}>{item}</div>) : ''}
                </span>
                ),
        },
        
        { title: "Suspect Risk Rating", field: "SuspectRiskRating"},
        { title: "Suspect Cyber DNA", field: "SuspectCyberDNA"},
        {
            title: "Relatives", 
            field: "TopThreeRelatives",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.TopThreeRelatives}>
                    {rowData.TopThreeRelatives ? rowData.TopThreeRelatives.split(' / ').map((item, i) => <div style={{width: 200}} key={i}>{item}</div>) : ''}
                </span>
            ),
        },
        {
            title: "Associates", 
            field: "TopThreeAssociates",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.TopThreeAssociates}>
                    {rowData.TopThreeAssociates ? rowData.TopThreeAssociates.split(' / ').map((item, i) => <div style={{width: 200}} key={i}>{item}</div>) : ''}
                </span>
            ),
        },
        {
            title: "RelatedURLs", 
            field: "RelatedURLs",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.RelatedURLs}>
                    {rowData.RelatedURLs ? rowData.RelatedURLs.split(' / ').map((item, i) => <div key={i}><a target="_blank" href={item}>{item}</a></div>) : ''}
                </span>
            ),
        },
        { 
            title: "Victim", 
            field: "Victim",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.Victim}</div>
            ),
            
        },
        { 
            title: "Victim DOB", 
            field: "VictimDOB",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.VictimDOB}</div>
            ),
            
        },
        {
            title: "Victim Online Aliases", 
            field: "VictimOnlineAliases",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.VictimOnlineAliases}>
                    {rowData.VictimOnlineAliases ? rowData.VictimOnlineAliases.split(' / ').map((item, i) => <div style={{width: 200}} key={i}>{item}</div>) : ''}
                </span>
            ),
        },
        { title: "Victimology", field: "Victimology"},
        { title: "Country of Victim", field: "CountryofVictim"},
        { title: "Victim State", field: "VictimState"},
        { 
            title: "Regional Coordinates of Victim", 
            field: "RegionalCoordinatesofVictim",
            render: (rowData) => (
                    <div style={{width: 200}}>
                        {rowData.RegionalCoordinatesofVictim ? <a href={"http://maps.google.com/maps?z=12&t=h&q=loc:" + rowData.RegionalCoordinatesofVictim.replace(" / ","+") + "" } target="_blank">{rowData.RegionalCoordinatesofVictim}</a> : ''}
                    </div>
            ),
            
        },
        { 
            title: "Victim Current Location", 
            field: "VictimCurrentLocation",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.VictimCurrentLocation}</div>
            ),
            
            
        },
        { title: "Victim Cyber DNA", field: "VictimCyberDNA"
            
        },
        { title: "COPINE", field: "COPINE"},
        { title: "Victim Email Addresses", field: "VictimEmailAddresses"},
        { title: "Victim Risk Rating", field: "VictimRiskRating"},
        { title: "Last Known Interaction", field: "LastKnownInteraction"},
        { title: "Regionally Relevant Sex Offenders", field: "RegionallyRelevantSexOffenders"},
        { title: "Magnitude", field: "Magnitude"},
        { title: "Solvability", field: "Solvability"},
        { title: "Technical Identified Risks",
            field: "TechnicalIdentifiedRisks",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.TechnicalIdentifiedRisks}>
                     {rowData.TechnicalIdentifiedRisks ? <div style={{width: 200}} >{rowData.TechnicalIdentifiedRisks.slice(0, 20)}...</div> : ''} 
                </span>
            ),
        },
        {
            title: "Phone Data", 
            field: "PhoneData",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.PhoneData}>
                    {rowData.PhoneData ? rowData.PhoneData.split(' / ').map((item, i) => <div style={{width: 200}} key={i}>{item}</div>) : ''}
                </span>
            ),
        },
        { 
            title: "AutoData", 
            field: "AutoData",
            render: (rowData) => (
                    <div style={{width: 200}}>{rowData.AutoData}</div>
            ),
            
        },
        {
            title: "Additional Information",
            field: "AdditionalInformation",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.AdditionalInformation}>
                    {rowData.AdditionalInformation ? <div style={{width: 200}}>{rowData.AdditionalInformation.slice(0, 20)}...</div> : ''} 
                </span>
            ),
        },
        {
            title: "Identified Risks",
            field: "IdentifiedRisks",
            render: (rowData) => (
                <span data-toggle="tooltip" data-placement="top" title={rowData.IdentifiedRisks}>
                    {rowData.IdentifiedRisks ? <div style={{width: 200}}>{rowData.IdentifiedRisks.slice(0, 20)}...</div> : ''} 
                </span>
            ),
        },
        { title: "AgencyID	", field: "AgencyID"}

        ];


    //table data
    const [data, setData] = useState([]);
    //checks if its updated, removing the need to have the page refresh.
    const [isUpdated, setIsUpdated] = useState(false);
    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const { height } = useWindowResize();

    const fetchData = useCallback(() => {
        api.get("/supplychain/")
            .then((res) => {
                setData(res.data);
                console.log(res.data);
                setIsUpdated(false);
            })
            .catch((error) => {
                console.log("Error");
                console.log(error.data);
            });
        // eslint-disable-next-line
    }, [isUpdated]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <div className="SupplyChainRecords" style={{ marginTop: "5vh" }}>
            <Grid container spacing={1}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <div>
                        {iserror && (
                            <Alert severity="error">
                                {errorMessages.map((msg, i) => {
                                    return <div key={i}>{msg}</div>;
                                })}
                            </Alert>
                        )}
                    </div>
                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            title="Supply Chain Data"
                            columns={COLUMNS}
                            data={data}
                            icons={tableIcons}
                            options={{
                                selection: true,
                                exportButton: false,
                                exportAllData: false,
                                emptyRowsWhenPaging: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 25, 50, 100, 200],
                                //headerStyle: { position: "sticky", top: 0 },
                                maxBodyHeight: height - 300,
                                overflowX: "hidden",
                                overflowY: "auto",
                            }}
                            actions={[
                                {
                                    position: "toolbarOnSelect",
                                    icon: SaveAlt,
                                    tooltip: "Export the selected rows!",
                                    onClick: (e, rowData) => {
                                        const fileName = "austin-tx-pd_CSV_Data";
                                        const builder = new CsvBuilder(
                                            fileName + ".csv"
                                        );
                                        builder
                                            .setColumns(
                                                COLUMNS.slice(0).map(
                                                    (columnDef) =>
                                                        columnDef.title
                                                )
                                            )
                                            .addRows(
                                                rowData.map((rowData) =>
                                                    COLUMNS.slice(0).map(
                                                        (columnDef) =>
                                                            rowData[
                                                                columnDef.field
                                                            ]
                                                    )
                                                )
                                            )
                                            .exportFile();
                                    },
                                },
                                
                               
                            ]}
                        />
                    </MuiThemeProvider>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    );
}

export default SupplyChainRecords;